/*
Adapted from the headroom.js library
https://github.com/WickyNilliams/headroom.js/blob/master/src/trackScroll.js
*/

type Options = {
  tolerance?: number,
  offset?: number,
};

interface FakeWindow {
  document: Document,
  innerHeight?: number,
  pageYOffset?: number,
}

interface Scroller {
  scrollHeight(): number,
  height(): number,
  scrollY(): number,
}

type ScrollDetail = {
  scrollY: number,
  lastScrollY: number,
  direction: 'down' | 'up',
  distance: number,
  isOutOfBounds: boolean,
  top: boolean;
  bottom: boolean;
};

// Passive event support

function passiveEventsSupported() {
  let supported = false;

  try {
    const options = {
      // eslint-disable-next-line getter-return
      get passive() {
        supported = true;
      },
    };
    window.addEventListener('test', options, options);
    window.removeEventListener('test', options, options);
  } catch (err) {
    supported = false;
  }

  return supported;
}


// Scrollers

class WindowScroller {
  win: FakeWindow;
  doc: Document;
  html: HTMLElement;
  body: HTMLElement;

  constructor(win: FakeWindow) {
    this.win = win;
    this.doc = win.document;
    this.html = this.doc.documentElement;
    this.body = this.doc.body;
  }

  scrollHeight() {
    return Math.max(
      this.body.scrollHeight,
      this.html.scrollHeight,
      this.body.offsetHeight,
      this.html.offsetHeight,
      this.body.clientHeight,
      this.html.clientHeight,
    );
  }

  height() {
    return this.win.innerHeight || this.html.clientHeight || this.body.clientHeight;
  }

  scrollY() {
    if (this.win.pageYOffset !== undefined) {
      return this.win.pageYOffset;
    }

    return (this.html || this.body.parentNode || this.body).scrollTop;
  }
}

class ElementScroller {
  element: HTMLElement;

  constructor(element: HTMLElement) {
    this.element = element;
  }

  scrollHeight() {
    return Math.max(
      this.element.scrollHeight,
      this.element.offsetHeight,
      this.element.clientHeight,
    );
  }

  height() {
    return Math.max(this.element.offsetHeight, this.element.clientHeight);
  }

  scrollY() {
    return this.element.scrollTop;
  }
}


function createScroller(element: any): Scroller {
  if (element && element.document && element.document.nodeType === 9) {
    return new WindowScroller((element));
  }
  if (element instanceof HTMLElement) {
    return new ElementScroller(element);
  }
  throw Error('Unknown elment type');
}


// Track scroll with requestAnimationFrame

export default function trackScroll(
  element: any,
  callback: (ScrollDetail) => void,
  {
    tolerance = 0,
    offset = 0,
  }: Options = {},
): { destroy: () => void } {
  const details = {
    scrollY: 0,
    lastScrollY: 0,
    direction: 'down',
    distance: 0,
    isOutOfBounds: false,
    top: false,
    bottom: false,
  };
  const scroller = createScroller(element);
  let lastScrollY = scroller.scrollY();
  let rafId;
  let scrolled = false;


  function update() {
    const scrollY = Math.round(scroller.scrollY());
    const height = scroller.height();
    const scrollHeight = scroller.scrollHeight();

    // reuse object for less memory churn
    details.scrollY = scrollY;
    details.lastScrollY = lastScrollY;
    details.direction = scrollY > lastScrollY ? 'down' : 'up';
    details.distance = Math.abs(scrollY - lastScrollY);
    details.isOutOfBounds = scrollY < 0 || scrollY + height > scrollHeight;
    details.top = scrollY <= offset;
    details.bottom = scrollY + height >= scrollHeight;

    callback(details);

    lastScrollY = scrollY;
    scrolled = false;
  }

  function handleScroll() {
    if (!scrolled) {
      scrolled = true;
      rafId = requestAnimationFrame(update);
    }
  }

  const eventOptions = passiveEventsSupported() ? { passive: true, capture: false } : false;

  element.addEventListener('scroll', handleScroll, eventOptions);
  update();

  return {
    destroy: () => {
      cancelAnimationFrame(rafId);
      element.removeEventListener('scroll', handleScroll, eventOptions);
    },
  };
}
