// Javascript file for site navigation
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import '@grubersjoe/slide-menu';
import { getCurrentUser } from './utils/currentUser';
import trackScroll from './utils/trackScroll';

const menuToggle: HTMLElement = document.querySelector('.js-menu-toggle');
const dashboardNavToggle = document.querySelector('.js-dashboard-nav-toggle');

const navigationContainer: HTMLElement = document.querySelector('.navigation-container');
const navigationIntersection: HTMLElement = document.querySelector('.js-nav-intersection');
const containerStickyClass = 'is-sticky';
const containerAppearClass = 'is-appearing';

// get buy section content
const buySection = document.querySelector('.js-buy-section');

// User dashboard submenu

if (dashboardNavToggle) {
  dashboardNavToggle.addEventListener('click', () => {
    dashboardNavToggle.classList.toggle('is-active');
    return false;
  });
}

// Helpers
function openTab(tab, list) {
  tab.classList.add('is-active');
  list.classList.add('is-active');
}

function closeTab(tab, list) {
  tab.classList.remove('is-active');
  list.classList.remove('is-active');
}

function trackMomentsDownload(label) {
  if ('dataLayer' in window && typeof window.dataLayer.push === 'function') {
    window.dataLayer.push({
      event: 'SendEvent',
      eventCategory: 'GG Download',
      eventAction: 'Download',
      eventLabel: label,
    });
  }
}
// End Helpers

// --- NEW MOBILE MENU ---
function setupBackButtons(backButton) {
  if (backButton instanceof HTMLElement) {
    backButton.addEventListener('click', (e: MouseEvent) => {
      menu.back();
    });
  }
}

function cleanBackLinks(backLink) {
  backLink.removeAttribute('data-action');
}

// Setup Slide menu
const menuElement = document.querySelector('.js-navigation-menu-mobile');
// Create html for mobile navigation used by SlideMenu
const submenuLinkAfter = `<img class="slide-menu__select-button" src="${window.staticUrl}img/icons/chevron-right_html.svg" alt="Select menu item" />`;
const backLinkBefore = `<img class="slide-menu__previous-button" src="${window.staticUrl}img/icons/chevron-left_html.svg" alt="Previous menu" />`;
let menu;
// Create SlideMenu
if (menuElement instanceof HTMLElement) {
  menu = new window.SlideMenu(menuElement, {
    showBackLink: true,
    submenuLinkAfter: submenuLinkAfter,
    backLinkBefore: backLinkBefore,
  });
}

const backButtons = Array.from(
  document.querySelectorAll('.slide-menu__previous-button'),
);
backButtons.forEach(setupBackButtons);

// Clean wrong navigation from lib
const backLinks = Array.from(
  document.querySelectorAll('.slide-menu__backlink'),
);
backLinks.forEach(cleanBackLinks);
// --- END NEW MOBILE NAV MENU ---

// Mobile menu toggle
if (menuToggle && navigationContainer) {
  const menuWrapper = navigationContainer.querySelector('.mobile-menu-wrapper');
  const modalBackground =
    navigationContainer.querySelector('.slide-menu__modal');
  const menuMainPanel = navigationContainer.querySelector(
    '.slide-menu__main-menu',
  );
  if (menuWrapper instanceof HTMLElement && modalBackground instanceof HTMLElement && menuMainPanel instanceof HTMLElement) {
    function closeMobileMenuProcedure() {
      navigationContainer.classList.remove('is-mobile-expanded');
      menuToggle.setAttribute('aria-expanded', 'false');
      modalBackground.classList.remove('modal-opened');
      menu.close();
      menu.navigateTo(menuMainPanel);
      clearAllBodyScrollLocks();
      setTimeout(() => {
        menuWrapper.classList.remove('is-active');
      }, 500);
    }

    window.onresize = function () {
      if (window.innerWidth > 920) {
        closeMobileMenuProcedure();
      }
    };

    menuToggle.onclick = () => {
      const isExpanded =
        navigationContainer.classList.contains('is-mobile-expanded');

      if (isExpanded) {
        closeMobileMenuProcedure();
      } else {
        navigationContainer.classList.add('is-mobile-expanded');
        menuToggle.setAttribute('aria-expanded', 'true');
        menuWrapper.classList.add('is-active');
        modalBackground.classList.add('modal-opened');
        menu.open();
        disableBodyScroll(menuMainPanel, {
          reserveScrollBarGap: true,
        });
      }
    };
  }
}

function initNavigationMenu(navigationContainer) {
  const mainNav = navigationContainer.querySelector('.main-navigation');
  if (!mainNav) return;
  const topNavItemIds = mainNav.dataset.mainItems.split(",");
  const topNavItems = topNavItemIds.map(
    (itemId) => ({
      id: itemId,
      tab: navigationContainer.querySelector(
        `.category-navigation__item--${itemId}`,
      ),
      list: navigationContainer.querySelector(
        `.category-navigation__list--${itemId}`,
      ),
    })
  );
  const downloadButtonData = {
    '/gg': {
      trackingLabel: 'GG Nav Download Button',
      downloadPath: '/gg/download/',
    },
    '/gg/moments': {
      trackingLabel: 'Moments Nav Download Button',
      downloadPath: '/gg/moments/download/',
    },
    '/gg/sonar': {
      trackingLabel: 'Sonar Nav Download Button',
      downloadPath: '/gg/sonar/download/',
    },
  };

  function openTopNav(itemId) {
    for (const topNavItem of topNavItems) {
      if (topNavItem.id === itemId) {
        openTab(topNavItem.tab, topNavItem.list);
      } else {
        closeTab(topNavItem.tab, topNavItem.list);
      }
    }
  }

  function closeTopNav() {
    for (const topNavItem of topNavItems) {
      closeTab(topNavItem.tab, topNavItem.list);
    }
  }

  if (topNavItems.every((item) => item.tab instanceof HTMLLIElement)) {
    const categoryNav = navigationContainer.querySelector(
      '.category-navigation',
    );
    const downloadButton = navigationContainer.querySelector(
      '.category-navigation__download-button a',
    );
    const onBuySection = buySection instanceof HTMLElement;
    const path = window.location.pathname;

    if (path.startsWith('/engine') || path.startsWith('/gg')) {
      openTopNav('software');
    } else if (path.startsWith('/blog') || path.startsWith('/esports')) {
      openTopNav('community');
    } else {
      openTopNav('products');
    }

    if (downloadButton instanceof HTMLElement) {
      if (downloadButtonData.hasOwnProperty(path)) {
        const buttonData = downloadButtonData[path];
        downloadButton.classList.remove('is-hidden');
        downloadButton.addEventListener('click', () => {
          trackMomentsDownload(buttonData.trackingLabel);
          setTimeout(() => {
            window.location = buttonData.downloadPath;
          }, 2000);
        });
      } else {
        downloadButton.classList.add('is-hidden');
      }
    }

    categoryNav.style.display = 'block';
    categoryNav.classList.add('is-expanded');
    onBuySection ? buySection.classList.add('nav-is-expanded') : null;

    for (const topNavItem of topNavItems) {
      topNavItem.tab.addEventListener('click', () => {
        const isActive = topNavItem.tab.classList.contains('is-active');
        if (isActive) {
          closeTopNav();
          categoryNav.classList.remove('is-expanded');
          if (onBuySection) {
            buySection.classList.remove('nav-is-expanded');
          }
        } else {
          openTopNav(topNavItem.id);
          categoryNav.style.display = 'block';
          categoryNav.classList.add('is-expanded');
          if (onBuySection) {
            buySection.classList.add('nav-is-expanded');
          }
        }
      });
    }
  }
}

if (navigationContainer instanceof HTMLElement) {
  initNavigationMenu(navigationContainer);
}

// Accessible dropdowns
// KEYCODES: 13 - enter
//           32 - space
//           9  - tab
if (navigationContainer instanceof HTMLElement) {
  const dropdowns = Array.from(
    navigationContainer.querySelectorAll('.js-navigation-dropdown-trigger'),
  );
  if (dropdowns) {
    dropdowns.forEach((dropdown) => {
      dropdown.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
          e.preventDefault();
          dropdown.classList.toggle('is-open');
          dropdown.classList.contains('is-open')
            ? dropdown.setAttribute('aria-expanded', 'true')
            : dropdown.setAttribute('aria-expanded', 'false');

          // Focus into submenu
          if (dropdown.classList.contains('is-open')) {
            const dropdownLinks = Array.from(
              dropdown.nextElementSibling.querySelectorAll('a'),
            );
            let focusedLink;
            dropdownLinks.forEach((link) => {
              link.addEventListener('keyup', (e: KeyboardEvent) => {
                e.preventDefault();
                // First item in dropdown list, fixes first keypress not getting registered
                if (e.keyCode === 9 && focusedLink === undefined) {
                  e.preventDefault();
                  focusedLink = dropdownLinks[0];
                  focusedLink.focus();
                }
              });

              link.addEventListener('keydown', (e: KeyboardEvent) => {
                if (e.keyCode === 9 && !e.shiftKey) {
                  e.preventDefault();
                  if (
                    dropdownLinks.indexOf(focusedLink) <
                    dropdownLinks.length - 1
                  ) {
                    // Not the last item in dropdown list, update focus
                    let focusedIndex = dropdownLinks.indexOf(focusedLink);
                    focusedLink = dropdownLinks[focusedIndex + 1];
                    dropdownLinks[focusedIndex + 1].focus();
                  } else {
                    // Last item in list, close everything
                    dropdown.classList.remove('is-open');
                    dropdown.setAttribute('aria-expanded', 'false');
                    focusedLink = null;
                    // Get next focus
                    const parent = dropdown.parentElement;
                    parent.nextElementSibling.firstChild.focus();
                    return;
                  }
                } else if (e.shiftKey && e.keyCode === 9) {
                  let focusedIndex = dropdownLinks.indexOf(focusedLink);
                  if (focusedIndex === 0) {
                    dropdown.classList.remove('is-open');
                    dropdown.setAttribute('aria-expanded', 'false');
                    return;
                  }
                  focusedLink = dropdownLinks[focusedIndex - 1];
                }
              });
            });
          }
        } else if (e.shiftKey && e.keyCode === 9) {
          dropdown.classList.remove('is-open');
          dropdown.setAttribute('aria-expanded', 'false');
        }
      });
    });
  }
}

// Handle user login state

// Give jwksCache an object that will just return modulus and exp
// instead of trying to find endpoint from jwksURI
class DummyCache {
  get() {
    let keyInfo = {
      modulus: window.jwtModulus,
      exp: window.jwtExp,
    };
    return keyInfo;
  }
  has() {
    return true;
  }
  set() {
    return null;
  }
}

// Helper Function: updateAccountLink()
// Populate username and avatar fields
function updateAccountLink() {
  const user = getCurrentUser();
  const name: HTMLElement[] = Array.from(document.querySelectorAll('.navigation-user__name'));
  const avatar_url: HTMLImageElement[] = Array.from(document.querySelectorAll('.navigation-user__avatar-url'));

  if (user && name && avatar_url) {
    name.map((name) => {
      name.innerText = user.name;
      name.style.opacity = '1';
    });
    avatar_url.map((obj) => {
      if (user.avatar_url) {
        obj.src = user.avatar_url;
      }
    });
  }
}



updateAccountLink();

// Navigation

function pinNavigation(container) {
  const cartWidget = document.querySelector('#js-cart-container');
  if (container.classList.contains(containerStickyClass)) {
    return;
  }

  // Calculate before positioning and scale
  const animateElements: HTMLElement[] = Array.from(
    container.querySelectorAll('.js-nav-animate-position'),
  );
  const pairs = animateElements.map((el) => {
    return {
      element: el,
      before: el.getBoundingClientRect(),
    };
  });

  // Add class
  container.classList.add(containerStickyClass);
  if (cartWidget instanceof HTMLElement) {
    cartWidget.classList.add(containerStickyClass);
  }

  // Calculate after positioning and set transforms
  pairs.forEach(({ element, before }) => {
    const after = element.getBoundingClientRect();
    const translateX = before.left - after.left;
    const translateY = before.top - after.top;

    element.style.transform = `translate(${translateX}px,${translateY}px)`;
    element.style.opacity = '1';
  });

  // Wait for the next frame so we know all the style changes have
  // taken hold.
  requestAnimationFrame(function () {
    pairs.forEach(({ element }) => {
      element.classList.add('is-animating');
      element.style.transform = '';
      element.style.opacity = '';
    });
  });
}

function unpinNavigation(container) {
  const cartWidget = document.querySelector('#js-cart-container');
  if (cartWidget instanceof HTMLElement) {
    cartWidget.classList.remove(containerStickyClass);
  }

  container.classList.remove(containerStickyClass);
  container.classList.remove(containerAppearClass);
  Array.from(container.querySelectorAll('.js-nav-animate-position')).forEach(
    (el) => el.classList.remove('is-animating'),
  );
}

function initNavigation(container: HTMLElement, placeholder: HTMLElement) {
  const category = container.querySelector('.category-navigation');

  // AB Test - GG Nav Moments Update
  const mainNav = container.querySelector('.main-navigation');

  let isPinned = false;
  let triggerY = 0;
  let placeholderHeight = 0;

  const onScroll = (details) => {
    if (!isPinned) {
      const scroll = window.pageYOffset || window.scrollY;
      const containerRect = container.getBoundingClientRect();
      let newTrigger = scroll + containerRect.top;

      // AB Test - GG Nav Moments Update
      if (mainNav) {
        const mainNavRect = mainNav.getBoundingClientRect();
        if (mainNavRect.height > 0) {
          newTrigger = scroll + mainNavRect.top;
        }
      } else if (category instanceof HTMLElement) {
        const categoryRect = category.getBoundingClientRect();
        if (categoryRect.height > 0) {
          newTrigger = scroll + categoryRect.top;
        }
      }
      if (newTrigger !== triggerY) {
        triggerY = newTrigger;
      }

      // Update placeholder height if necessary
      const containerHeight = scroll + containerRect.top + containerRect.height;

      if (containerHeight != placeholderHeight) {
        placeholder.style.height = `${containerHeight}px`;
      }
      placeholderHeight = containerHeight;
    }

    if (details.scrollY > triggerY) {
      pinNavigation(container);
      isPinned = true;
    } else {
      unpinNavigation(container);
      isPinned = false;
    }
  };

  setTimeout(() => trackScroll(window, onScroll), 100);
}

if (navigationContainer && navigationIntersection) {
  initNavigation(navigationContainer, navigationIntersection);
}
